import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HistoryOfPashminaComponent } from './history-of-pashmina/history-of-pashmina.component';
import { HowWeMakeComponent } from './how-we-make/how-we-make.component';
import { OurStoryComponent } from './our-story/our-story.component';
import { RouterModule } from '@angular/router';
import { HopCarouselComponent } from './history-of-pashmina/hop-carousel/hop-carousel.component';
import { PreSpinningComponent } from './how-we-make/pre-spinning/pre-spinning.component';
import { SpinningComponent } from './how-we-make/spinning/spinning.component';
import { WeavingComponent } from './how-we-make/weaving/weaving.component';
import { FinishingComponent } from './how-we-make/finishing/finishing.component';
import { FinishingCarouselComponent } from './how-we-make/finishing/finishing-carousel/finishing-carousel.component';
import { WeavingCarouselComponent } from './how-we-make/weaving/weaving-carousel/weaving-carousel.component';
import { PrespinCarouselComponent } from './how-we-make/pre-spinning/prespin-carousel/prespin-carousel.component';
import { SpinCarouselComponent } from './how-we-make/spinning/spin-carousel/spin-carousel.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    HistoryOfPashminaComponent,
    HowWeMakeComponent,
    OurStoryComponent,
    HopCarouselComponent,
    PreSpinningComponent,
    SpinningComponent,
    WeavingComponent,
    FinishingComponent,
    FinishingCarouselComponent,
    WeavingCarouselComponent,
    PrespinCarouselComponent,
    SpinCarouselComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild([]),
  ]
})
export class AboutPashminaModule { }
