
export interface ITokenPostPreview {
    "clarityId": string,
    "id": string,
    "postId": number,
    "preview": string,
    "type": eTokenType,
    "title": string,
    "addedBy": {
        username: string,
        displayName: string
    },
    "addedOn": string
}

export enum eTokenPostPreviewState {
    "preview" = "preview",
    "post" = "post",
}

export enum eTokenType {
    "public" = "public",
    "private" = "private"
}

export enum eTokenContent {
    "info" = "info",
    "file" = "file",
    "video" = "video"
}