<carousel interval="5000" [isAnimated]="true">
  <slide class="animated fadeIn">
    <img src="assets/images/ProjMM.Concept.1.jpg" alt="TeraZukuri" style="display: block; width: 100%;">
  </slide>
  <slide class="animated fadeIn">
    <img src="assets/images/ProjMM.Concept.2.jpg" alt="TeraZukuri" style="display: block; width: 100%;">
  </slide>
  <slide class="animated fadeIn">
    <img src="assets/images/ProjMM.Concept.3.jpg" alt="TeraZukuri" style="display: block; width: 100%;">
  </slide>
  <slide class="animated fadeIn">
    <img src="assets/images/ProjMM.Concept.4.jpg" alt="TeraZukuri" style="display: block; width: 100%;">
  </slide>
</carousel>
