<div class="row">
    <div class="col-md-4">
        <carousel interval="5000" isAnimated="true">
            <slide class="animated fadeIn">
              <img src="assets/images/Project-MM.1.jpg" alt="TeraZukuri" style="display: block; width: 100%;">
            </slide>
            <slide class="animated fadeIn">
              <img src="assets/images/Project-MM.2.jpg" alt="TeraZukuri" style="display: block; width: 100%;">
            </slide>
          </carousel>
    </div>
    <div class="col-md-8">
        <div>
            <blockquote class="content"> 
                {{ 'projectMM.projectMM.content' | translate}}
            </blockquote>
        </div>
    </div>
</div>