import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qrcode-dialog',
  templateUrl: './qrcode-dialog.component.html',
  styleUrls: ['./qrcode-dialog.component.css']
})
export class QrcodeDialogComponent implements OnInit {
  public content: string;
  
  constructor() { }

  public ngOnInit(): void {
    this.content = window.location.href;
  }

}
