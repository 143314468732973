import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConceptComponent } from './concept/concept.component';
import { ProductComponent } from './product/product.component';
import { ProjectMMComponent } from '../project-mm/project-mm.component';
import { ProjectMmContentComponent } from './project-mm-content/project-mm-content.component';
import { CarouselComponent } from './concept/carousel/carousel.component';
import { LtCommitmentComponent } from './lt-commitment/lt-commitment.component';
import { LtcCarouselComponent } from './lt-commitment/ltc-carousel/ltc-carousel.component';
import { ProductWhbaseComponent } from './product/product-whbase/product-whbase.component';
import { ProductDbbaseComponent } from './product/product-dbbase/product-dbbase.component';
import { WhbaseImageGridComponent } from './product/product-whbase/whbase-image-grid/whbase-image-grid.component';
import { DbbaseImageGridComponent } from './product/product-dbbase/dbbase-image-grid/dbbase-image-grid.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    ProjectMMComponent,
    ProjectMmContentComponent,
    ConceptComponent,
    ProductComponent,
    CarouselComponent,
    LtCommitmentComponent,
    LtcCarouselComponent,
    ProductWhbaseComponent,
    ProductDbbaseComponent,
    WhbaseImageGridComponent,
    DbbaseImageGridComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild([]),
  ]
})
export class ProjectMMModule { }
