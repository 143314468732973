export const environment = {
  production: false,

  mockApi: "assets/mock-api",
  
  submitMessageApi: "https://gaufqhc6ib.execute-api.ap-south-1.amazonaws.com/dev",
  
  supplier: "https://h74pql7eda.execute-api.ap-south-1.amazonaws.com/dev",
  product : "https://4kt8w2gwn4.execute-api.ap-south-1.amazonaws.com/dev/api",
  
  inventoryApi: "https://970j153emf.execute-api.ap-south-1.amazonaws.com/dev/api/v1",
  trackerApiv1: "https://rjbngd3zl8.execute-api.ap-south-1.amazonaws.com/dev/api/v1",

  distributorApiv1: "https://h74pql7eda.execute-api.ap-south-1.amazonaws.com/dev",
  companySettings: "https://h74pql7eda.execute-api.ap-south-1.amazonaws.com/dev", 

  cognito: {
    "userPoolId" : "ap-south-1_BF72HO3i1",
    "region": "ap-south-1",
    "clientId": "36q94cd1g318lh997rbck7d7eu"
  }
};
