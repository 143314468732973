<br>
<div class="container">
    <div class="row">
        <!-- Navigation Tabs -->
        <div id="list-example" class="nav nav-pills">
            <a class="nav-link" [routerLink]="['/ProjectMM']" fragment="projectMM">{{ 'projectMM.projectMM.nav' | translate}}</a>
            <a class="nav-link" [routerLink]="['/ProjectMM']" fragment="concept">{{ 'projectMM.concept.nav' | translate}}</a>
            <a class="nav-link" [routerLink]="['/ProjectMM']" fragment="ltcommitment">{{ 'projectMM.ltcommitment.nav' | translate}}</a>
        </div>
    </div>
    
    <!-- Navigation Tabs Content-->
    <div class="content">
        <div class="sub-head">
            {{ 'projectMM.projectMM.nav' | translate}}
        </div>
        <section id="projectMM" class="home-section">
            <pmm-project-mm-content></pmm-project-mm-content>
        </section>
        <div class="sub-head">
            {{ 'projectMM.concept.nav' | translate}}
        </div>
        <section id="concept" class="about-section">
            <pmm-concept></pmm-concept>
        </section>
        <div class="sub-head">
            {{ 'projectMM.ltcommitment.nav' | translate}}
        </div>
        <section id="ltcommitment" class="contact-section">
            <pmm-ltcommitment></pmm-ltcommitment>
        </section>
    </div>
</div>