<div class="full-screen">
    <button mat-icon-button class="close" (click)="ngOnDestroy()" color="warn">
        <mat-icon>close</mat-icon>
    </button>

    <router-outlet name="popupContent"></router-outlet>
    
    <!-- <section class="buttons">
        <button mat-icon-button class="icons" color="primary">
            <mat-icon>tag</mat-icon>
        </button>
        <button mat-icon-button class="icons" color="primary">
            <mat-icon>attach_file</mat-icon>
        </button>
        <button mat-icon-button class="icons" color="primary">
            <mat-icon>videocam</mat-icon>
        </button>
    </section> -->
</div>

