<carousel interval="5000" [isAnimated]="true" s>
  <slide class="animated fadeIn">
    <img class="center-image" src="assets/images/HistoryPic1.jpg" alt="TeraZukuri" style="display: block; width: 100%;">
  </slide>
  <slide class="animated fadeIn">
    <img class="center-image" src="assets/images/HistoryPic2.jpg" alt="TeraZukuri" style="display: block; width: 100%;">
  </slide>
  <slide class="animated fadeIn">
    <img class="center-image" src="assets/images/HistoryPic3.jpg" alt="TeraZukuri" style="display: block; width: 100%;">
  </slide>
</carousel>
