<div [class.JALang]="isJap" class="flex-row">
    <div>
        <ul-navbar></ul-navbar>
    </div>
    <div class="mt-5 pt-3">
        <router-outlet></router-outlet>
    </div>
    
    <div>
        <ul-footer></ul-footer>
    </div>
    
</div>