import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { LocationStrategy } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SwalAlertService } from 'src/app/services/swal-alert.service';
import { TokenService } from '../../_services/token.service';
import { TokenStateService } from '../../_services/token.state.service';
import { ITokenPostPreview, eTokenPostPreviewState, eTokenType } from '../../_types/enum';
import { ITokenPost, ITokenPostListItem } from '../../_types/interface';
import { ClarityTokenPostDialogComponent } from './clarity-token-post-dialog/clarity-token-post-dialog.component';
import { ClarityTokenPostMobileComponent } from './clarity-token-post-mobile/clarity-token-post-mobile.component';

export enum eTokenPostDevice {
  "mobile" = "mobile",
  "desktop" = "desktop"
}

@Component({
  selector: 'app-clarity-token-post',
  templateUrl: './clarity-token-post.component.html',
  styleUrls: ['./clarity-token-post.component.scss']
})

export class ClarityTokenPostComponent implements OnInit {

  @Input() public data: ITokenPostPreview;

  public tokenPostInfo: ITokenPost;

  readonly eTokenPostPreviewState: typeof eTokenPostPreviewState = eTokenPostPreviewState;
  readonly eTokenType: typeof eTokenType = eTokenType;
  readonly Math = Math;

  public state: eTokenPostPreviewState = eTokenPostPreviewState['preview'];
  public device: eTokenPostDevice;
  private _isDialogOpen: boolean = false;

  constructor(
    private _dialogSrv: MatDialog,
    private _breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _dataSrv: TokenService,
    private _alertSrv: SwalAlertService,
    private _stateSrv: TokenStateService,
    private _location: LocationStrategy
  ) { }

  public ngOnInit(): void {
    this._breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.XSmall] || state.breakpoints[Breakpoints.Small]) {
        this.device = eTokenPostDevice.mobile;
      } else {
        this.device = eTokenPostDevice.desktop;
      }
    });

    // Code to handle back event in mobile and browser
    this._location.onPopState(() => {
      if (this._isDialogOpen) {
        this._router.navigate(['/projects/xx']);
        this._isDialogOpen = false;
      }
    });
  }

  public getTokenPosPreviewInfo(previewInfo: ITokenPostPreview): void {
    this._dataSrv.getTokenPostInfo(previewInfo.id, previewInfo.postId).subscribe({
      next: data => this.tokenPostInfo = data,
      error: err => this._alertSrv.swalAlertFailure(err),
      complete: () => {
        this._stateSrv.setTokenPostData(this.tokenPostInfo);
        this.openToken();
      }
    });
  }

  public openToken(): void {
    this._isDialogOpen = true;

    this._router.navigate([{
      outlets: {
        "popupContent": [this.tokenPostInfo.meta.startsWith]
      }
    }]);
    this._stateSrv.setDevice(this.device);
    
    if (this.device == eTokenPostDevice.desktop) {
      const dialog = this._dialogSrv.open(ClarityTokenPostDialogComponent, {
        data: this.tokenPostInfo,
        width: "50rem",
        maxHeight: '100vh'
      });

      

    } else if (this.device == eTokenPostDevice.mobile) {
      const dialog = this._dialogSrv.open(ClarityTokenPostMobileComponent, {
        data: this.tokenPostInfo,
        panelClass: ["mobile-post"],
        width: "50rem",
        maxHeight: '100vh'
      });

      
    }
  }

}
