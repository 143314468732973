import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ILambdaResponse } from '../_models.global/global-interfaces';
import swal from 'sweetalert2';



@Injectable({providedIn: 'root'})
export class SwalAlertService {

  constructor(private _router: Router) { }

	fireSwalAlert(res: ILambdaResponse, navigationRoute?: string) {
		
		if (res.err) {
			this.swalAlertFailure(res, navigationRoute)
		} else if (res.msg) {
			this.swalAlertSuccess(res, navigationRoute)
		}
	}
	private swalAlertSuccess(res: ILambdaResponse, navigationRoute?: string): void {
		swal.fire({
			titleText: 'Success',
			text: res.msg,
			icon: 'success'
		}).then(() => {
			if (navigationRoute) { 
				if (res.id) {
					this._router.navigate([navigationRoute, res.id]);
				} else {
					this._router.navigate([navigationRoute]);
				}
			}
    	});
  	}
	
	async swalAlertConfirm(text: string, icon?: any): Promise<boolean> {
		icon = icon || "warning";
		
		let response = swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: icon,
			showCancelButton: true,
  			confirmButtonText: text,
		}).then( (result) => {
			if (result.isConfirmed)
				return true
			return false
		})

		return response
	}

	public swalAlertFailure(err, navigationRoute?): void {
		swal.fire({
			title: "Action couldn't be completed",
			text: err.error || err,
			icon: 'error'
		}).then(() => {
			if (navigationRoute) { 
				if (err.id) {
					this._router.navigate([navigationRoute, err.id]);
				} else {
					this._router.navigate([navigationRoute]);
				}
			}
		});
	}	
}
