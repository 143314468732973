import { Component, OnInit } from '@angular/core';
import { IMangaPage } from 'src/app/_models.global/global-interfaces';

@Component({
	selector: 'app-our-story',
	templateUrl: './our-story.component.html',
	styleUrls: ['./our-story.component.css']
})

export class OurStoryComponent {
	//Action 1. Display Comic Pages based on the selected language - Adi

	//1.1 Initialze an empty Array which will be looped in the HTML
  	public mangaPages: Array<IMangaPage>;

  	constructor() {
		  //1.2 Identify the current language from local storage 
		const lang: string = localStorage.getItem('locale');
		//1.3 Based on the current language, pass appropriate language as a parameter to the function
		switch (lang) {
			case 'en':
				this.populateMangaPage('en');
				break;
			case 'jp' :
				this.populateMangaPage('jp');
				break;
			default:
				this.populateMangaPage('jp');
				break;
		}
	} 

	//1.4 Based on the received input, display images from appropriate manga folder
  	private populateMangaPage (lang: string): void {
		//1.4.1 Clear the existing images so that manga pages do not get appended on every language change
		this.mangaPages = []
		//1.4.2 Complete - Run a loop to populate all the manga pages
    	for(let i=0; i<=10; i++) {
      		this.mangaPages.push({
				"id" : i,
				"src" : `assets/media/manga/${lang}/image${i}.jpg`
			});
    	}
  	}	
}
