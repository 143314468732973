import { NgModule } from '@angular/core';
import { HomeComponent } from './home.component';
import { HomeCarouselComponent } from './home-carousel/home-carousel.component';
import { HomeContentComponent } from './home-content/home-content.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HomePopupComponent } from './home-popup/home-popup.component';



@NgModule({
  declarations: [
    HomeComponent,
    HomeCarouselComponent,
    HomeContentComponent,
    HomePopupComponent,
  ],
  imports: [
    SharedModule
  ]
})
export class HomeModule { }
