import { Component, OnInit } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { TokenStateService } from 'src/app/lib/token-card/_services/token.state.service';
import { IFile } from 'src/app/lib/token-card/_types/interface';

enum eFileState {
  pdf = "pdf",
  image = "image",
  video = "video"
}

@Component({
  selector: 'app-token-post-file',
  templateUrl: './token-post-file.component.html',
  styleUrls: ['./token-post-file.component.scss']
})

export class TokenPostFileComponent implements OnInit {

  readonly eFileState: typeof eFileState = eFileState;

  public state: eFileState;
  public src: string;
  public file: IFile;

  constructor(
    public _stateSrv: TokenStateService
  ) { }

  public ngOnInit(): void {
    this._stateSrv.tokenPostData$.subscribe(res => {
      if (res?.content?.files?.length) {
        const file = res.content?.files[0];
        
        this.file = file;
        
        const images = ["image/jpeg", "image/jpg", "image/png"]
        const files = ["application/pdf"];

        const imageFileTypes = {
          state : eFileState.image,
          fileTypes: images
        };

        const filesFileTypes = {
          state: eFileState.pdf,
          fileTypes: files
        };

        [ imageFileTypes, filesFileTypes ].forEach(fileTypes => {
          if (fileTypes.fileTypes.includes(file.fileType)) {
            this.state = fileTypes.state
          }  
        })
      }      
    });
  }

}
