import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {  faBuilding,faBackward, faUserCog,faUserCircle,faChevronCircleDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-company-settings-layout',
  templateUrl: './company-settings-layout.component.html',
  styleUrls: ['./company-settings-layout.component.css']
})
export class CompanySettingsLayoutComponent {

  public isExpanded: boolean;
	readonly faBuilding: IconDefinition = faBuilding;
	readonly faUserCog: IconDefinition = faUserCog;
	readonly faUserCircle: IconDefinition = faUserCircle;
	readonly faChevronCircleDown: IconDefinition = faChevronCircleDown;
	readonly faBackward: IconDefinition = faBackward;


  
  readonly navigationMenu = [
    { 'icon': 'dashboard', 'svgIcon': '', 'routerLink': '/admin/dashboard', 'menu': 'Dashboard' }, 
    { 'icon': 'dashboard_customize', 'svgIcon': '', 'routerLink': '/company', 'menu': 'Company Dashboard' }, 
    { 'icon': 'supervisor_account', 'svgIcon': '', 'routerLink': '/company/shipping-agent', 'menu': 'Shipping Agent' }, 
    { 'icon': 'landscape', 'svgIcon': '', 'routerLink': '/company/uom', 'menu': 'UOM' },
    { 'icon': 'category', 'svgIcon': '', 'routerLink': '/company/category', 'menu': 'Category' }
  ]

  constructor(readonly authSrv: AuthService) { }

}
