import { Component, OnInit } from '@angular/core';
import { SwalAlertService } from 'src/app/services/swal-alert.service';
import { TokenService } from '../../_services/token.service';
import { ITokenPostPreview, eTokenType } from '../../_types/enum';
import { ITokenPostListItem } from '../../_types/interface';

@Component({
  selector: 'app-token-private',
  templateUrl: './token-private.component.html',
  styleUrls: ['./token-private.component.css']
})
export class TokenPrivateComponent implements OnInit {

  public tokenPrivateInfo: ITokenPostListItem[];
  constructor(
    private _dataSrv: TokenService,
    private _aletSrv: SwalAlertService
  ) { }

  public ngOnInit(): void {
    this._dataSrv.getTokenPrivateInfoList().subscribe({
      next: data => this.tokenPrivateInfo = data,
      error: err => this._aletSrv.swalAlertFailure(err)
    })
  }
  public onWheel(event: WheelEvent): void {
    if (event.deltaY > 0) document.getElementById('private-wheel')!.scrollLeft += 40;
    else document.getElementById('private-wheel')!.scrollLeft -= 40;
  }
}
