import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CategoryToProduct'
})
export class CategoryToProductPipe implements PipeTransform {
  
  transform(productList: any[], category: string): Array<any> {
    
    if (!productList || !category) { return productList }
    
    return productList.filter(productList => productList.category.indexOf(category) !== -1);    
  }

}
