<blockquote>
    <p>{{'home.content.quote_line_1' | translate }}</p>
    <p>{{'home.content.quote_line_2' | translate }}</p>
    <p style="text-align:right">{{'home.content.quote_author' | translate }}</p>
</blockquote>
<section id="home-message">
    <h2>{{'home.content.title.aboutTera' | translate }}</h2>
    <br>
    <p>{{'home.content.message.1' | translate }}</p>
    <p>{{'home.content.message.2' | translate }}</p>
    <br>
</section>
