import { Component, OnInit } from '@angular/core';
import { TokenStateService } from 'src/app/lib/token-card/_services/token.state.service';

@Component({
  selector: 'app-token-post-video',
  templateUrl: './token-post-video.component.html',
  styleUrls: ['./token-post-video.component.css']
})
export class TokenPostVideoComponent implements OnInit {

  public videoSrc: string;
  constructor(
    private _stateSrv: TokenStateService
  ) { }

  public ngOnInit(): void {
    this._stateSrv.tokenPostData$.subscribe(res => {
      if (res?.content?.videos?.length) {
        this.videoSrc = res.content?.videos[0].url;
      }      
    })
  }

}
