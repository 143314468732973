import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { IMangaPage } from 'src/app/_models.global/global-interfaces';

@Component({
	selector: 'app-home-popup',
	templateUrl: './home-popup.component.html',
	styleUrls: ['./home-popup.component.css']
})

export class HomePopupComponent {
	//Action 1. Display Comic Pages based on the selected language - Adi
	//Action 2. Navigate the user to the Story Page and/or Close the Modal - Reddy

	//1.1 Initialze an empty variable which will be rendered in the HTML
	public previewPage: IMangaPage;

	//2.1 Inject Services, route for navigation and dialogref for closing the modal
	constructor (private route: Router, public dialogRef: MatDialogRef<HomePopupComponent>) {
		//1.2 Identify the current language from local storage 
		const lang:string = localStorage.getItem('locale');
		//1.3 Based on the current language, choose appropriate preview image
		switch (lang) {
			case 'en':
				this.previewPage = {
					id : 0,
					src : `assets/media/manga/en/image0.jpg`
				}
				break;
			case 'jp' :
				this.previewPage = {
					id : 0,
					src : `assets/media/manga/jp/image0.jpg`
				}
				break;
			default: 
				this.previewPage = {
					id : 0,
					src : `assets/media/manga/jp/image0.jpg`
				}
				break;
		}
	}

	//2.2 If user chooses to navigate, navigate to Story and close the modal
	public navigate(): void {
		this.route.navigate(['OurStory']);
		this.dialogRef.close();
	}
}
