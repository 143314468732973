import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { eTokenPostDevice } from "../_models/clarity-token-post/clarity-token-post.component";
import { ITokenPost } from "../_types/interface";

@Injectable({
    providedIn: 'root'
})

export class TokenStateService {
    private tokenPostData: BehaviorSubject<ITokenPost> = new BehaviorSubject({} as ITokenPost);
    public tokenPostData$: Observable<ITokenPost> = this.tokenPostData.asObservable(); 
    
    private deviceState: BehaviorSubject<eTokenPostDevice> = new BehaviorSubject({} as eTokenPostDevice);
    public deviceState$: Observable<eTokenPostDevice> = this.deviceState.asObservable();

    public setTokenPostData(data: ITokenPost) {
        return this.tokenPostData.next(data);
    }
    public setDevice(data: eTokenPostDevice) {
        return this.deviceState.next(data);
    }

}