import { Injectable, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CognitoUserPool, ICognitoUserPoolData, AuthenticationDetails, IAuthenticationDetailsData, CognitoUser, CognitoUserSession, ICognitoUserData } from 'amazon-cognito-identity-js';
import { ActivatedRoute, Router } from '@angular/router';
import { SwalAlertService } from './swal-alert.service';
import { ILambdaResponse } from '../_models.global/global-interfaces';
import { ICognitoAuthError, CognitoAuthError } from '../lib/angular-errors/cognito-auth-error-handler';
import { JwtHelperService } from '@auth0/angular-jwt';

export interface ICognitoDeliveryDetails {
    "CodeDeliveryDetails": {
        "AttributeName": string,
        "DeliveryMedium": string,
        "Destination": string
    }
};

@Injectable({
    'providedIn': 'root'
})

export class AuthService {

    public currentUser: string = localStorage.getItem(`CognitoIdentityServiceProvider.${environment.cognito.clientId}.LastAuthUser`) || null;
    
    private _accessToken: string = localStorage.getItem(`CognitoIdentityServiceProvider.${environment.cognito.clientId}.${this.currentUser}.accessToken`) || null;
    
    private _userPool: ICognitoUserPoolData = {
        UserPoolId: environment.cognito.userPoolId,
        ClientId: environment.cognito.clientId,
    };

    constructor(private router: Router, private route: ActivatedRoute, private swalAlert: SwalAlertService) { };

    get isLoggedIn(): boolean { return (!!this._accessToken && !!this.currentUser) };

    public async signInToCognito(credentials: IAuthenticationDetailsData, newUserSignIn?): Promise<void> {
        const authenticationDetails = new AuthenticationDetails(credentials);
        const cognitoUserPool = new CognitoUserPool(this._userPool);
        const userData = {
            "Username": credentials.Username || newUserSignIn.email,
            "Pool": cognitoUserPool
        };

        const cognitoUser = new CognitoUser(userData);
        
        await new Promise((resolve, reject) => {

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result: any) => {
                    console.log(result)
                    this._accessToken = result.getAccessToken().getJwtToken();
                    this.currentUser = result.accessToken.payload.username;

                    this.router.navigate([this.route.snapshot.queryParamMap.get('returnUrl') || '/admin']);
                    resolve(this._accessToken);
                },
                onFailure: (err: ICognitoAuthError) => reject(new CognitoAuthError(err)),

                newPasswordRequired: (userAttributes) => { 
                    delete userAttributes.email_verified;
                    if (newUserSignIn) {
                        cognitoUser.completeNewPasswordChallenge(newUserSignIn.newPassword, null, {
                            onSuccess: (result: any) => {
                                this._accessToken = result.getAccessToken().getJwtToken();
                                this.currentUser = result.accessToken.payload.username;

                                this.router.navigate([this.route.snapshot.queryParamMap.get('returnUrl') || '/admin']);
                                resolve(this._accessToken);;
                            },
                            onFailure: (err) => new CognitoAuthError(err),
                        });
                    } else {
                        reject(userAttributes);
                    };
                }
            });
        });
    };

    public signOutOfCognito(): void {
        localStorage.clear();
        const res: ILambdaResponse = {};
        
        res['msg'] = "You have successfully signed Out from Tera Admin App";

        this.swalAlert.fireSwalAlert(res, '/');
        this.currentUser = null;
    };

    // User Details
    public getUserDetails(): any {
        const idTokenDetails = localStorage.getItem(`CognitoIdentityServiceProvider.${environment.cognito.clientId}.${this.currentUser}.idToken`);
                
        const jwtHelper = new JwtHelperService();
        const appData = jwtHelper.decodeToken(idTokenDetails);
        const userDetails = { 
            'email': appData?.email || null,
            'username': this.currentUser || null
        };
        return userDetails;
    };
    
    /* Password Reset Methods (2) */
    // 1 Anonymous User

    //Step 1: Notify the user with a verification code
    public async initiatePasswordResetFlowForAnonymousUser(credentials: IAuthenticationDetailsData): Promise<ICognitoDeliveryDetails | Error> {
        const cognitoUserPool = new CognitoUserPool(this._userPool);

        const userData = {
            "Username": credentials.Username,
            "Pool": cognitoUserPool
        };
        const cognitoUser = new CognitoUser(userData);

        return await new Promise((resolve, reject) => {
            cognitoUser.forgotPassword({
                onSuccess: (data: ICognitoDeliveryDetails) => resolve(data),
                onFailure: (err: ICognitoAuthError) => reject(new CognitoAuthError(err))
            });
        });
    };

    // Step 2: Submit Verification code and New Password
    public async changePasswordAsAnonymousUser(verificationCode: string, newPassword, username): Promise<any> {
        const cognitoUserPool = new CognitoUserPool(this._userPool);
        const userData = {
            "Username": username,
            "Pool": cognitoUserPool
        };

        const cognitoUser = new CognitoUser(userData);

        return await new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onSuccess: () => resolve(this.swalAlert.fireSwalAlert({ 'msg': 'Password reset is successful. Please sign-in with your new password.' })),
                onFailure: (err: ICognitoAuthError) => reject(new CognitoAuthError(err)),
            });
        });
    };

    // 2. Signed In User
    public async changePasswordAsSignedInUser(oldPassword, newPassword): Promise<void> {
        const cognitoUserPool = new CognitoUserPool(this._userPool);
        const userData = {
            "Username": this.currentUser,
            "Pool": cognitoUserPool,
        };

        cognitoUserPool.getCurrentUser().getSession((err, session: CognitoUserSession) => {
            if (err) return new CognitoAuthError(err);
            const cognitoUser = new CognitoUser(userData);

            cognitoUser.setSignInUserSession(session);

            cognitoUser.changePassword(oldPassword, newPassword, (err: ICognitoAuthError, result: any) => {
                return err ? new CognitoAuthError(err) : this.swalAlert.fireSwalAlert({ 'msg': 'Password Reset is Successful' });
            });
        });
    };
};
