<div class="body">
    <div class="error-page">
        <div class="content">
            <img src="assets/images/logo-image.png" alt="Tera Logo">
            <div>
                <h1 [attr.data-text]="errorCode">{{ errorCode }}</h1>
                <h4 [attr.data-text]="errorMessage">{{ errorMessage }}</h4>
                <p>Sorry, the <strong class="label">{{ label }}</strong> you're looking for doesn't exist.</p>    
            </div>
            <div class="d-block btns">
                <a [routerLink]="routerLink">return to {{ routerLabel }}</a>
            </div>
        </div>
    </div>
</div>