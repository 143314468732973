<nav id="navbar" class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
	<a class="navbar-brand" routerLink="/"> <img src="assets/images/logo-image.png"></a>
	<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
		aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		<span class="navbar-toggler-icon"></span>
	</button>

	<div class="collapse navbar-collapse" id="navbarSupportedContent">
		<ul class="navbar-nav ml-auto">
			<li class="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
				<a class="nav-link" routerLink="/home"><span>{{ 'navbar.link.home' | translate}} </span></a>
			</li>

			<li class="nav-item dropdown active">
				<a routerLink="/Product" class="nav-link dropdown-toggle" id="navbarDropdownMenuLink"
					data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<span>{{ 'navbar.link.projectMM' | translate}} </span>
				</a>
				<div class="dropdown-menu" data-toggle="collapse" data-target=".navbar-collapse.show"
					aria-labelledby="navbarDropdownMenuLink">
					<a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show"
						routerLink="/ProjectMM"> {{ 'navbar.link.projectMM.concept' | translate }}
					</a>
					<a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show"
						routerLink="/Product"> {{'navbar.link.projectMM.product' | translate }}
					</a>
				</div>
			</li>

			<li class="nav-item dropdown active">
				<a routerLink="/OurStory" class="nav-link dropdown-toggle" id="navbarDropdownMenuLink"
					data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<span> {{ 'navbar.link.aboutPashmina' | translate}} </span>
				</a>
				<div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
					<a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show"
						routerLink="/HistoryofPashmina"><span> {{ 'navbar.link.aboutPashmina.historyOfPashmina' | translate}} </span>
					</a>
					<a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show"
						routerLink="/HowWeMakeatTera"><span> {{ 'navbar.link.aboutPashmina.howWeMakeatTera' | translate }} </span>
					</a>
					<a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show"
						routerLink="/OurStory"><span> {{ 'navbar.link.aboutPashmina.ourStory' | translate}} </span>
					</a>
				</div>
			</li>
			<li class="nav-item active">
				<a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
					routerLink="/ContactUs"><span> {{ 'navbar.link.contactUs' | translate}} </span>
				</a>
			</li>
			<li class="nav-item active">
				<a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
					(click)="changeLanguage('jp')"><span>{{ 'navbar.link.JP' | translate}} </span>
				</a>
			</li>
			<li class="nav-item active">
				<a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
					(click)="changeLanguage('en')"><span>{{ 'navbar.link.EN' | translate}} </span>
				</a>
			</li>
			<li class="nav-item active">
				<a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" 
					routerLink="/Order"><span class="fa fa-opencart"></span>
				</a>
			</li>
		</ul>
	</div>
</nav>