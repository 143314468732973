import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserLayoutComponent } from './layouts/user-layout-1/user-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { ProjectMMComponent } from './pages/project-mm/project-mm.component';
import { ProductComponent } from './pages/project-mm/product/product.component';
import { ContactComponent } from './pages/contact/contact.component';
import { OurStoryComponent } from './pages/about-pashmina/our-story/our-story.component';
import { HowWeMakeComponent } from './pages/about-pashmina/how-we-make/how-we-make.component';
import { HistoryOfPashminaComponent } from './pages/about-pashmina/history-of-pashmina/history-of-pashmina.component';
import { OrderFormComponent } from './pages/order-form/order-form.component';
import { AuthGuard } from './services/auth-guard.service';
import { AdminPortalLayoutComponent } from './layouts/admin-portal-layout/admin-portal-layout.component';
import { AdminPortalRoutingModule } from './features-admin-portal/features-admin-portal.routing';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { LoginComponent } from './auth/login/login.component';
import { UserSettingsLayoutComponent } from './layouts/user-settings-layout/user-settings-layout.component';
import { ConfigurationsUserSettingsRoutingModule } from './configurations-user-settings/configurations-user-settings-routing.module';
import { ConfigurationsCompanySettingsRoutingModule } from './configurations-company-settings/configurations-company-settings-routing.module';
import { PageNotFoundComponent } from './shared/html/page-not-found/page-not-found.component';
import { CompanySettingsLayoutComponent } from './layouts/company-settings-layout/company-settings-layout.component';
import { ProjectsComponent } from './layouts/projects/projects.component';
import { TokenPostFileComponent } from './lib/token-card/_models/clarity-token-post/_common/token-post-file/token-post-file.component';
import { TokenPostInfoComponent } from './lib/token-card/_models/clarity-token-post/_common/token-post-info/token-post-info.component';
import { TokenPostVideoComponent } from './lib/token-card/_models/clarity-token-post/_common/token-post-video/token-post-video.component';
import { TokenPostTimelineComponent } from './lib/token-card/_models/clarity-token-post/_common/token-post-timeline/token-post-timeline.component';
import { SlateAngularDemoComponent } from './layouts/slate-angular-demo/slate-angular-demo.component';

export const routes: Routes = [
	{
		path: '',
		redirectTo: 'projects',
		pathMatch: 'full'
	},
	{
		path: 'slate-angular',
		component: SlateAngularDemoComponent
	},
	{
		path: 'info', 
		outlet: 'popupContent',
		component: TokenPostInfoComponent
	}, {
		path: 'file',
		outlet: 'popupContent',
		component: TokenPostFileComponent
	}, {
		path: 'video',
		outlet: 'popupContent',
		component: TokenPostVideoComponent
	}, {
		path: 'timeline',
		outlet: 'popupContent',
		component: TokenPostTimelineComponent
	}, {
		path: '',
		component: UserLayoutComponent,
		children: [{
			path: 'home',
			component: HomeComponent
		}, {
			path: 'ProjectMM',
			component: ProjectMMComponent
		}, {
			path: 'HowWeMakeatTera',
			component: HowWeMakeComponent
		}, {
			path: 'HistoryofPashmina',
			component: HistoryOfPashminaComponent
		}, {
			path: 'OurStory',
			component: OurStoryComponent
		}, {
			path: 'ContactUs',
			component: ContactComponent
		}, {
			path: 'Product',
			component: ProductComponent
		}, {
			path: 'Order',
			component: OrderFormComponent
		}]
	},
	{
		path: '',
		redirectTo: 'projects',
		pathMatch: 'full'
	},
	{
		path: 'projects',
		component: ProjectsComponent,
	},
	{
		path: 'projects',
		children: [{
			path: 'xx',
			component: ProjectsComponent,
		}]
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
