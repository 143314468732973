<div class="timeline" *ngIf="tokenPostInfo" id="timeline-scroll" 
    [style.margin-top]="isSafari ? '2em' : ''"
    style="overflow-y: hidden; max-height: 93vh" (wheel)="onWheel($event)">
    <ul>
        <li *ngFor="let item of tokenPostInfo?.content?.timeline; let i = index">
            <div class="content">
                <h5>{{item.title}}</h5>
                <div [innerHtml]="item.innerHtml"></div>               
            </div>
            <div class="time">
                <h6>{{item.date | date}}</h6>
            </div>
        </li>       
    </ul>
</div>