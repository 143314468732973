import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'psy-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit, OnChanges {
  @Input() public errorCode: number;
  @Input() public label: string;
  @Input() public returnTo: string;

  public errorMessage: string;
  public routerLabel: string;
  public routerLink: string;

  constructor(private _router: Router) { }

  public ngOnInit(): void {
    /*By calling ngChanges function from the ngOnInit, we can handle the page not found event.
      Since ngOnchanges isn't triggered by navigation from routerOutlet, we are calling the function manually from onInit -- Adi*/
    this.ngOnChanges();
  }

  public ngOnChanges(): void {
    
    this.label = this.label || 'page';
    this.errorCode = this.errorCode || 404;
    
    switch(this.returnTo) {
      case('home'):
        this.routerLabel = this.routerLabel || 'Home';
        this.routerLink = this.routerLink || '/home';
      case('admin-portal'):
        this.routerLabel = this.routerLabel || 'Admin Portal';
        this.routerLink = this.routerLink || '/admin';
      default:
        this.routerLabel = this.routerLabel || 'Home';
        this.routerLink = this.routerLink || '/home';
    }
    

    this.errorMessage = `Oops! ${this.label} not found.`
  };
};
