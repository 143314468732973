import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { BrowserStateService } from 'src/app/lib/services/browser.state';
import { TokenStateService } from 'src/app/lib/token-card/_services/token.state.service';
import { ITokenPost } from 'src/app/lib/token-card/_types/interface';

@Component({
  selector: 'app-token-post-timeline',
  templateUrl: './token-post-timeline.component.html',
  styleUrls: ['./token-post-timeline.component.scss']
})
export class TokenPostTimelineComponent implements OnInit {

  public tokenPostInfo: ITokenPost;
  public showMore: object = {};
  public isSafari: boolean = false;

  constructor(private _stateSrv: TokenStateService, private domSanitizer: DomSanitizer, private _browser: BrowserStateService) { }

  public ngOnInit(): void {
    this._stateSrv.tokenPostData$.subscribe(data => {
      this.tokenPostInfo = data;
      if (this.tokenPostInfo.content.timeline) {
        this.tokenPostInfo.content.timeline.forEach((entry, index) => {
          const cfg = {};
          const converter = new QuillDeltaToHtmlConverter(entry.description.ops, cfg);
          const html = converter.convert();
          if (html.includes('iframe')) {
            this.tokenPostInfo.content.timeline[index].innerHtml = this.domSanitizer.bypassSecurityTrustHtml(html);
          } else {
            this.tokenPostInfo.content.timeline[index].innerHtml = html;
          }
        })
      }
    });
    
    this.isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1
  }

  public onWheel(event: WheelEvent): void {
    if (event.deltaY > 0) document.getElementById('timeline-scroll')!.scrollTop += 40;
    else document.getElementById('timeline-scroll')!.scrollTop -= 40;
  }

}
