<i class="fa fa-close float-right" (click)="this.dialogRef.close()"></i>
<div id="mangaPopup" class="container">  
  <h3>{{ 'home.modal.title' | translate}}</h3>
  <div>
    <img [src]="previewPage.src" width="350em">
  </div>
  <div>
    <button class="btn btn-primary float-left" (click)="navigate()">{{ 'home.modal.readComic' | translate}}</button>
    <button class="btn btn-success float-right" (click)="this.dialogRef.close()">{{ 'home.modal.viewSite' | translate}}</button>
  </div>
</div>
