<mat-card class="main-grid project-xx card-border">
  <div class="title-grid">
    <div class="token-id">
      <mat-chip-list>
        <mat-chip> PJTXX-0001 </mat-chip>
    </mat-chip-list>      
    </div>
    <div class="qrcode">
      <qr-img class="code" (click)="getQrCode()" img="assets/images/zoom.svg" [content]="content" size="50"
        img-width="35" img-height="35">
      </qr-img>
    </div>
  </div>
  <div class="image-grid">
    <img mat-card-image src="assets/token-data/preview-image.jpeg" alt="Photo of a Image">
  </div>
  <div class="name-grid">
    <app-token-info></app-token-info>
  </div>
  <div class="panel-grid">
    <mat-accordion>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>Public Information</mat-panel-title>
          <mat-panel-description>
            <mat-icon>public</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-token-public></app-token-public>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Private Information</mat-panel-title>
          <mat-panel-description>
            <mat-icon>lock</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-token-private></app-token-private>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</mat-card>