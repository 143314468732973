import { Component } from '@angular/core';

@Component({
  selector: 'prj-whbase-image-grid',
  templateUrl: './whbase-image-grid.component.html'
})

export class WhbaseImageGridComponent {
  public activeImg: number = 3;
}
