<h1 class="page-heading">{{ 'order.contact.title' | translate }}</h1>
<div class="container">

    <form #order="ngForm" (ngSubmit)="submit(order)">
        <!-- Contact Info Start -->
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="fname">{{ 'order.contact.fname' | translate }} <span class="required">*</span> :</label>
                    <input ngModel name="fname" #fname="ngModel" minlength="2" maxlength="20" id="fname" class="form-control" type="text" required>
                    <div *ngIf="fname.touched && !fname.valid" class="alert alert-danger">
                        <div *ngIf="fname.errors.required">{{ 'order.form.fname.validation.1' | translate }}</div>
                        <div *ngIf="fname.errors.minlength">{{ 'order.form.fname.validation.2' | translate }}</div>
                    </div>                    
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label for="lname">{{ 'order.contact.lname' | translate }} <span class="required">*</span> :</label>
                    <input ngModel name="lname" #lname="ngModel" minlength="2" maxlength="20" id="lname" class="form-control" type="text" required>
                    <div *ngIf="lname.touched && !lname.valid" class="alert alert-danger">
                        <div *ngIf="lname.errors.required">{{ 'order.form.lname.validation.1' | translate }}</div>
                        <div *ngIf="lname.errors.minlength">{{ 'order.form.lname.validation.2' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="email">{{ 'order.contact.email' | translate }} <span class="required">*</span> :</label>
            <input  ngModel name="email" #email="ngModel" pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}" id="email" class="form-control" type="email" required>
            <div *ngIf="email.touched && !email.valid" class="alert alert-danger">
                <div *ngIf="email.errors.required">{{ 'order.form.email.validation.1' | translate }}</div>
                <div *ngIf="email.errors.pattern"> {{ 'order.form.email.validation.2' | translate }}</div>
            </div>
        </div>
        <!-- Contact Info End -->

        <!--Product Selection-->
        <div class="form-check center-div">
            <label class="sub-head">{{ 'order.contact.intProduct' | translate }}</label>

            <div class="row">
                <div class="col-sm-6">
                    <img src="assets/images/Product_brown_base2.jpg" class="form-image" alt="Nature">
                    <br>
                    <input ngModel name="dbBase" #dbBase="ngModel" class="form-check-input" for="productSelect" type="checkbox">
                    <label class="form-check-label product-link" for="productSelect" routerLink="/Product" fragment='product-1'>            
                        {{ 'order.contact.product1' | translate}}
                    </label>
                </div>
                <div class="col-sm-6">
                    <a>
                        <img src="assets/images/Product_white_base2.jpg" class="form-image" alt="Nature">
                    </a>
                    <br>
                    <input ngModel name="whBase" #whBase="ngModel" class="form-check-input" for="productSelect" type="checkbox">
                    <label class="form-check-label product-link" for="productSelect" routerLink="/Product" fragment='product-2'>            
                        {{ 'order.contact.product2' | translate}}
                    </label>
                </div>
            </div>
        </div>
        <!--Product Selection End-->
        <br>
        <!--User Contact Details-->
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="altContact"> <strong>{{ 'order.contact.altContact.title' | translate }} : </strong></label>
                    <input ngModel name="altContact" #altContact="ngModel" class="form-control" id="altContact" type="input" placeholder="{{ 'order.contact.altContact.placeholder' | translate }}">
                </div>
            </div>    
        
            <div class="col-md-6">
                <div class="form-group">
                    <div class="form-group">
                        <label for="altContact">{{ 'order.contact.hearTera.title' | translate }} <span class="required">*</span> :</label>
                        <select required ngModel name="hearTera" #hearTera="ngModel" class="form-control" id="altContact" type="input">
                            <option value=""></option>
                            <option *ngFor="let optionHearAbtTera of optionsHearabtTera" value="{{optionHearAbtTera}}">
                                {{ optionHearAbtTera }}
                            </option>
                            <option value='other'>{{ 'order.contact.hearTera.option0' | translate }}</option>
                        </select>
                        <!--Input Box if 'other is selected-->
                        <br *ngIf="hearTera.value === 'other'">
                        <input *ngIf="hearTera.value === 'other'" ngModel name="otherContactMethod" #otherContactMethod="ngModel" class="form-control" id="hearTera" type="input" placeholder="{{ 'order.contact.optionHearAbtTera.placeholder' | translate }}">
                    </div>
                    <div *ngIf="hearTera.touched && !hearTera.valid" class="alert alert-danger">
                        <div *ngIf="hearTera.errors.required">{{ 'order.form.hearTera.validation.1' | translate }}</div>
                    </div>                    
                </div>
            </div>
        </div>
        
        <div class="form-group">
            <label for="altContact">{{ 'order.contact.msgToTera' | translate }} :</label>
            <textarea ngModel name="msgToTera" #msgToTera="ngModel" class="form-control" id="altContact" type="text" rows="5"></textarea>
        </div>
        <!--Contact Details End-->
        <div class="form-check center-div">
            <input ngModel name="tandc" #tandc="ngModel" class="form-check-input" type="checkbox" value="" id="tandc" required [value]="true">
            <label class="form-check-label" for="tandc">
                {{ 'order.contact.tandc' | translate }}
            </label>
        </div>
        <br>
        
        <div class="center-div">
            <button type="submit" class="btn btn-success" [disabled]="!order.valid"> {{ 'order.contact.submit' | translate }} </button>
        </div>
    </form>
    <br>
    <div class="center-div">
        <p>{{ 'order.contact.disclaimer' | translate }}</p>
    </div>
    
</div>