import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { BrowserStateService } from './lib/services/browser.state';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit {
  title = 'TeraZukuri';

  constructor(
    public router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _browserSrv: BrowserStateService
  ) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-164543427-1',
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    })

    this.matIconRegistry.addSvgIcon("jp-craftsman", this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/H_ORIMONO.svg'));
    this.matIconRegistry.addSvgIcon("jp-woodwork", this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/H_MOKKO.svg'));
    this.matIconRegistry.addSvgIcon("jp-hanami", this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/N_HANAMI.svg'));
    this.matIconRegistry.addSvgIcon("jp-bag", this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/B_BAITEN.svg'));
    this.matIconRegistry.addSvgIcon("jp-stall", this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/F_YATAI.svg'));
    this.matIconRegistry.addSvgIcon("jp-surfing", this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/N_SAFUIN.svg'));
    this.matIconRegistry.addSvgIcon("jp-music", this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg-icons/B_IYAHON-GAIDO.svg'));
  };

  public ngOnInit(): void {
    this._browserSrv.browserState = window.navigator.userAgent.toLowerCase();
  }
};
