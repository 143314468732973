import { Component } from '@angular/core';

@Component({
  selector: 'hwmat-prespin-carousel',
  templateUrl: './prespin-carousel.component.html',
  styleUrls: [ ]
})

export class PrespinCarouselComponent { }

