import { NgModule } from '@angular/core';
import { CategoryToProductPipe } from './category-to-product.pipe';
import { EnumSelectPipe } from './enum-to-array.pipe';
import { PsyFilter } from './psy-filter-for-ES.pipe';
import { SearchPipe } from './search.pipe';
import { TooltipListPipe } from './tooltip-list.pipe';
import { HighlightSearchPipe } from './highlightSearchPipe';

const pipes = [
    CategoryToProductPipe,
    EnumSelectPipe,
    TooltipListPipe,
    SearchPipe,
    PsyFilter,
    HighlightSearchPipe
]

@NgModule({
    declarations: [pipes],
    exports: [pipes]
})

export class PipesModule {}