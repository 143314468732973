import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { HomePopupComponent } from 'src/app/pages/home/home-popup/home-popup.component';

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html'
})

export class UserLayoutComponent {
  public isJap: boolean;

  constructor (translate: TranslateService, dialog: MatDialog) {
    if (translate.currentLang == 'jp') {
      this.isJap = true;
    } else {
      this.isJap = false;
    }

    dialog.open(HomePopupComponent, {position: { top: '5rem'}})   
  };
  
};