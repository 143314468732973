import { LocationStrategy } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight, faGlobe, faLock, faTag } from '@fortawesome/free-solid-svg-icons';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { SwalAlertService } from 'src/app/services/swal-alert.service';
import { TokenService } from '../../../_services/token.service';
import { TokenStateService } from '../../../_services/token.state.service';
import { eTokenType, ITokenPostPreview } from '../../../_types/enum';
import { ITokenPost, ITokenPostListItem } from '../../../_types/interface';

export enum eTokenPostInfoState {
  "view" = "view",
}
enum ItemBarStates {
  "loading" = "loading",
  "done" = "done",
  "noItems" = "noItems"
}

@Component({
  selector: 'app-clarity-token-post-dialog',
  templateUrl: './clarity-token-post-dialog.component.html',
  styleUrls: ['./clarity-token-post-dialog.component.scss']
})
export class ClarityTokenPostDialogComponent implements OnInit {

  public zoom_to: number = 1.0;
  public tokenPostDetails: ITokenPost;
  public state: eTokenPostInfoState = eTokenPostInfoState['view'];
  public sbState: ItemBarStates = ItemBarStates.loading;
  public sb: any = document.getElementById("scrollbar");
  public arrowStatus = {
    left: false,
    right: false
  };

  public faGlobe: IconDefinition = faGlobe;
  public faLock: IconDefinition = faLock;
  public faTag: IconDefinition = faTag;

  public chipsList: string[] = [];
  public associationsList: string[] = [];
  public filesList: string[] = [];

  readonly eTokenType: typeof eTokenType = eTokenType;
  readonly faChevronLeft: IconDefinition = faChevronLeft;
  readonly faChevronRight: IconDefinition = faChevronRight;
  readonly eTokenPostInfoState: typeof eTokenPostInfoState = eTokenPostInfoState;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ITokenPost,
    public dialogRef: MatDialogRef<ClarityTokenPostDialogComponent>,
    private _stateSrv: TokenStateService
  ) { }


  public ngOnInit(): void {
    this._stateSrv.tokenPostData$.subscribe(res => {
      this.tokenPostDetails = res;
      this.chipsList = this.tokenPostDetails.associations;
      if (this.chipsList.length) {
        this.sbState = ItemBarStates.done;
      } else {
        this.sbState = ItemBarStates.noItems;
      }
    });
  }

  public ngOnDestroy(): void {
    this.dialogRef.close();
  }

  public getAssociations(): void {
    this.chipsList = this.associationsList;
  }

  public getFiles(): void {
    this.chipsList = ["file1", "file2", "file3", "file4"];
  }

  public callBackFn(pdf: PDFDocumentProxy): void {
  }

  public ngAfterViewInit(): void {
    const interval = setInterval(() => {
      /* if (this.sbState == ItemBarStates.done) {
        this.sb = document.getElementsByClassName("mat-chip-list-wrapper")[0]
        this.sb.addEventListener("mouseenter", this.setArrowStatus, false);
        this.sb.addEventListener("mouseout", this.setArrowStatus, false);

        this.setArrowStatus();
        clearInterval(interval)
      } */
    }, 1000)
  };

  public setArrowStatus(): void {
    this.arrowStatus = {
      left: Boolean(this.sb?.scrollLeft > 10),
      right: Boolean(this.sb?.scrollLeft < (this.sb?.scrollWidth - this.sb?.clientWidth - 10))
    };
    //this._ref?.detectChanges();
  };

  public scrollBy(direction: string): void {
    let value, scrollBy = 150;

    if (direction == '+') {
      value = this.sb.scrollLeft + scrollBy;
    } else {
      value = this.sb.scrollLeft - scrollBy;
    }

    this.sb.scrollTo({
      left: value,
      behavior: 'smooth'
    });

    setTimeout(() => this.setArrowStatus(), 100)
  }

} 
