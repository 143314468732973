import { NgModule } from '@angular/core';
import { UserSettingsLayoutComponent } from './user-settings-layout.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [UserSettingsLayoutComponent],
  imports: [SharedModule, RouterModule.forChild([])]
})
export class UserSettingsLayoutModule { }
