<div class="row">
    <div class="col-md-4">
        <div >
            <hwmat-weaving-carousel></hwmat-weaving-carousel>
        </div>
    </div>
    <div class="col-md-8">
        <div>
            <blockquote class="content">
                {{ 'aboutPashmina.howWeMake.weaving.content-1' | translate}}
                <br><br>
                {{ 'aboutPashmina.howWeMake.weaving.content-2' | translate}}
                <br><br>
                {{ 'aboutPashmina.howWeMake.weaving.content-3' | translate}}
            </blockquote>
        </div>
    </div>
</div>