import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenCardComponent } from 'src/app/lib/token-card/token-card.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  constructor(
    private _dialogSrv: MatDialog,
    private _router: Router,
    private _activated: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    console.log(this._router.url.split('/'));
    if (this._activated.snapshot.queryParams.token || this._router.url.split('/')[2]?.includes('xx')) {
      const dialogRef = this._dialogSrv.open(TokenCardComponent, {
        data: { id: 'xx' },
        width: '450px'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (!result) return this._router.navigate(['/projects']);
      });
    }
  }

  public navigateToken(id: string): void {
    this._router.navigate(['/projects/xx']);
  }

}
