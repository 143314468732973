import { Component } from '@angular/core';
import { IRouteLink } from 'src/app/_models.global/global-interfaces';

@Component({
  selector: 'ul-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent {
  readonly navbar: string = 'Terazukuri';

  readonly links: IRouteLink[] = [
    {
      name : 'Home',
      routerLink : '/home'
    }, {
      name : 'Project MM',
      routerLink : '/home'
    }, {
      name : 'About Pashmina',
      routerLink : '/home'
    }, {
      name : 'Contact Us',
      routerLink : '/home'
    }
  ];
  
  public changeLanguage(lang: string) {
    localStorage.setItem('locale', lang);
    window.location.reload();
  }
}
