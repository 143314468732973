import { Component } from '@angular/core';
import { ICredit } from 'src/app/_models.global/global-interfaces';

@Component({
  selector: 'ul-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent {

  readonly credits: ICredit[] = [
    {
      "title" : "Product",
      "values" : ["Mook and Mir Family"]
    }, {
      "title" : "Introduction to Pashmina",
      "values" : ["P.Jarh"]
    }, {
      "title" : "Translation",
      "values" : ["Ryota","Nobu-さん", "Miki Akashi-さん","Yukiko-さん", "Kurumi-さん", "Emi", "Ayana", "amy0201"]
    }, {
      "title" : "Proofread",
      "values" : ["Gabi", "Emi", "Adi"]
    }, {
      "title" : "Designer",
      "values" : ["Bam", "Ray"]
    }, {
      "title" : "Bag",
      "values" : ["Ray", "Bam and Team"]
    }, {
      "title" : "Prototype Test",
      "values" : ["Nut", "Natty", "Ung", "Liew", "Chieri-さん", "Pahn", "Gabi", "Nica", "Tiffany", "Emi", "Yuki", "Iulia", 
      "Adi’s friend", "Some Japanese stranger I do", "not remember the name….sorry"]
    }, {
      "title" : "Web",
      "values" : ["Manohar", "Obu", "Srinu", "Reddy", "Sudi", "Adi"]
    }, {
      "title" : "Model",
      "values" : ["Tiff", "Max", "Pat", "Yuria"]
    }, {
      "title" : "Consultant",
      "values" : ["Bank", "Che", "Shiyo", "Saori", "Gil 先生", "Ishikawa 先生", "Karl 先生", "Nakamura 先生", "Megumi 先生", "Nica",
      "Reno", "Liew", "Nut", "Natty", "Yuria", "Ayana","K. Took"]
    }
  ]
}
