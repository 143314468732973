<div *ngIf="device == eTokenPostDevice['desktop']" class="text-wrapper" [innerHtml]="innerHtml" id="info-scroll"
    style="overflow-y: hidden; max-height: 93vh" (wheel)="onWheel($event)">
</div>

<div class="main text-wrapper" *ngIf="device == eTokenPostDevice['mobile'] && tokenPostInfo">
    <div class="blog-post">
        <div class="blog-post__img">
            <img [src]="tokenPostInfo?.preview" alt="image">
        </div>
        <div class="blog-post__info">
            <div class="blog-post__date">
                <span>{{tokenPostInfo?.addedBy?.displayName}} </span>
                <span>{{tokenPostInfo?.addedOn | date}}</span>
            </div>
            <p class="blog-post__text" [innerHtml]="innerHtml">
            </p>
        </div>
    </div>
</div>
