<br>
<div class="row">
    <div class="col-md-4">
        <div >
            <prj-whbase-image-grid></prj-whbase-image-grid>
        </div>
    </div>
    <div class="col-md-8">
        <div>
            <blockquote class="content">
                {{ 'projectMM.product.prod2.desc1' | translate }}
                <br><br>
                {{ 'projectMM.product.prod2.desc2' | translate }}
                <br><br>
                <strong>{{ 'projectMM.product.prod.weave' | translate }}</strong> : {{ 'projectMM.product.prod2.weave' | translate}}
                <br>
                <strong>{{ 'projectMM.product.prod.edge' | translate }}</strong> : {{ 'projectMM.product.prod2.edge' | translate }}
                <br>
                <strong>{{ 'projectMM.product.prod.dimension' | translate }}</strong> : {{ 'projectMM.product.prod2.dimension' | translate }}
                <br>
                <strong>{{ 'projectMM.product.prod.base-color' | translate }}</strong> : {{ 'projectMM.product.prod2.base-color' | translate }}
                <br>
                <strong>{{ 'projectMM.product.prod.length' | translate }}</strong> : {{ 'projectMM.product.prod2.length' | translate }}
                <br>
                <strong>{{ 'projectMM.product.prod.mid-color' | translate }}</strong> : {{ 'projectMM.product.prod2.mid-color' | translate }}
                <br>
                <strong>{{ 'projectMM.product.prod.weight' | translate }}</strong> : {{ 'projectMM.product.prod2.weight' | translate }}
                <br>
                <strong>{{ 'projectMM.product.prod.price' | translate }}</strong> : {{ 'projectMM.product.prod2.price' | translate }}
                <br><br>
                
                <button routerLink="/Order" class="btn btn-primary"><span class="fa fa-opencart"></span> {{ 'projectMM.product.prod.order' | translate }}</button>
            </blockquote>
        </div>
    </div>
</div>