import { ErrorHandler, Injectable } from "@angular/core";
import swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})

export class AppErrorHandler implements ErrorHandler {
      
    public handleError(originalError?: any) {
        swal.fire({
			title: "Something went wrong...",
			text: originalError.error || originalError,
			icon: 'error'
        })
        console.error(originalError)
    }
}
