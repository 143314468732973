import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { OrderFormModule } from './pages/order-form/order-form.module';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';

import { LayoutsModule } from './layouts/layouts.module';
import { PagesModule } from './pages/pages.module';
import { RouterModule } from '@angular/router';

import { ConfigurationsUserSettingsModule } from './configurations-user-settings/configurations-user-settings.module';
import { PositiveIntDirective } from './lib/directives/positive-int.directive';
import { AppErrorHandler } from './lib/angular-errors/app-error-handler';
import { FeaturesAdminPortalModule } from './features-admin-portal/features-admin-portal.module';
import { SharedModule } from './shared/shared.module';
import { HttpLoaderInterceptor } from './lib/services/loader.interceptor';
import { AuthModule } from './auth/auth.module';
import { KeyValuePipe } from '@angular/common';
import { ConfigurationsCompanySettingsModule } from './configurations-company-settings/configurations-company-settings.module';
import { HighlightSearchPipe } from './shared/pipes/highlightSearchPipe';
import { TokenCardModule } from './lib/token-card/token-card.module';
import { ExamplePdfViewerComponent } from './example-pdf-viewer/example-pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

export function initApp(http: HttpClient, translate: TranslateService) {

	return () => new Promise<boolean>((resolve: (res: boolean) => void) => {
		const defaultLocale = 'jp';
		const translationsUrl = '/assets/i18n/translations';
		const sufix = '.json';
		const storageLocale = localStorage.getItem('locale');
		const locale = storageLocale || defaultLocale;

		forkJoin([
			http.get(`/assets/i18n/dev.json`).pipe(
				catchError(() => of(null))
			),
			http.get(`${translationsUrl}/${locale}${sufix}`).pipe(
				catchError(() => of(null))
			)
		]).subscribe((response: any[]) => {
			const devKeys = response[0];
			const translatedKeys = response[1];

			translate.setTranslation(defaultLocale, devKeys || {});
			translate.setTranslation(locale, translatedKeys || {}, true);

			translate.setDefaultLang(defaultLocale);
			translate.use(locale);
			resolve(true);
		});
	});
}

@NgModule({
	declarations: [
		AppComponent,
		PositiveIntDirective,
		ExamplePdfViewerComponent
	],
	
	imports: [
		RouterModule.forRoot(routes, {
			anchorScrolling: 'enabled',
			scrollPositionRestoration: 'enabled',
			scrollOffset: [0, 100],
			relativeLinkResolution: 'legacy'
		}),
		AppRoutingModule,
		SharedModule,
		FormsModule,		
		// AuthModule,
		// FeaturesAdminPortalModule,
		PagesModule,
		LayoutsModule,
		OrderFormModule,
		// ConfigurationsUserSettingsModule,
		// ConfigurationsCompanySettingsModule,

		TranslateModule.forRoot(),
		
		// TokenCardModule,
	],

	providers: [
		KeyValuePipe,
		HighlightSearchPipe,
		{ provide: ErrorHandler, useClass: AppErrorHandler },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpLoaderInterceptor, multi: true },
		{ provide: APP_INITIALIZER, useFactory: initApp, deps: [HttpClient, TranslateService], multi: true }
	],

	bootstrap: [AppComponent]
})

export class AppModule { }
