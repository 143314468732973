<div class="row">
    <!-- The expanding image container -->
    <div class="container">
      <!-- Expanded image -->
      <img class="side-image" src= "assets/images/Product_brown_base{{activeImg}}.jpg">    
    </div>
  </div>
  
  <div class="row">
      <div class="col-4">
          <a (mouseenter)="activeImg = 3">
              <img src="assets/images/Product_brown_base3.jpg" class="side-image selects" alt="Nature">
          </a>    
      </div>
      <div class="col-4">
          <a (mouseenter)="activeImg = 2">
              <img src="assets/images/Product_brown_base2.jpg" class="side-image selects" alt="Nature">
          </a>    
      </div><div class="col-4">
          <a (mouseenter)="activeImg = 1">
              <img src="assets/images/Product_brown_base1.jpg" class="side-image selects" alt="Nature">
          </a>    
      </div>
  </div>