<div class="container">
    <div class="row">
        <div class="center-div">
            <about-pashmina-carousel></about-pashmina-carousel>
        </div>
    </div>
</div>
<br>
<div class="container">
    <div class="row">
        <blockquote class="content">
            {{ 'aboutPashmina.history.content_1' | translate}}
            <br><br>
            {{ 'aboutPashmina.history.content_2' | translate}}
            <br><br>
            {{ 'aboutPashmina.history.content_3' | translate}}
            <br><br>
            {{ 'aboutPashmina.history.content_4' | translate}}
        </blockquote>
    </div>
</div>