<div class="container">    
    <h1 class="page-heading"> {{ 'aboutPashmina.contactUs.heading' | translate}}</h1>
    
    <div class="row">
        <div class="col-md-6">
            <div class="ContactUsBox"> 
                <img src="assets/images/QRCode.jpg">
            </div>
        </div>
        
        <div class="col-md-6">
            <div id="ContactInfo" class="ContactUsBox" class="d-md-flex flex-column align-items-md-start"> 
                <div class="d-flex justify-content-center">
                    <strong>{{ 'aboutPashmina.contactUs.tel' | translate }}</strong>: 070-3960-5704
                </div>
                <div class="d-flex justify-content-center">
                    <strong>{{ 'aboutPashmina.contactUs.ig' | translate }}</strong> 
                    <a href="https://www.instagram.com/tera_zukuri/?igshid=1nh4v4442tdgs" target="_blank">: Tera-Zukuri</a>
                </div>
                <div class="d-flex justify-content-center">
                    <strong>{{ 'aboutPashmina.contactUs.email' | translate }}</strong>: akewises@hotmail.com
                </div>
                <div class="d-flex justify-content-center">
                    <button routerLink="/Order" class="btn btn-primary">{{ 'aboutPashmina.contactUs.contact-us' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
    <br>
</div>