import { Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { TokenStateService } from 'src/app/lib/token-card/_services/token.state.service';
import { ITokenPost } from 'src/app/lib/token-card/_types/interface';
import { eTokenPostDevice } from '../../clarity-token-post.component';

@Component({
  selector: 'app-token-post-info',
  templateUrl: './token-post-info.component.html',
  styleUrls: ['./token-post-info.component.scss']
})

export class TokenPostInfoComponent implements OnInit {
  public tokenPostInfo: ITokenPost;
  public innerHtml: string;
  readonly eTokenPostDevice: typeof eTokenPostDevice = eTokenPostDevice;
  public device: eTokenPostDevice;
  private _breakpointObserver: any;

  constructor(private _stateSrv: TokenStateService) { }

  public ngOnInit(): void {
    this._stateSrv.deviceState$.subscribe(data => this.device = data)
    this._stateSrv.tokenPostData$.subscribe(data => this.tokenPostInfo = data)
    
    const cfg = {};
    const converter = new QuillDeltaToHtmlConverter(this.tokenPostInfo.content.info.ops, cfg);
    const html = converter.convert();
    this.innerHtml = html;
  }
  public onWheel(event: WheelEvent): void {
    if (event.deltaY > 0) document.getElementById('info-scroll')!.scrollTop += 40;
    else document.getElementById('info-scroll')!.scrollTop -= 40;
  }

}
