import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  	providedIn: 'root'
})

export class OrderFormSubmitService {
  
	constructor(private http: HttpClient) { }
	
	readonly submitMessageUrl: string = environment.submitMessageApi;
	
	/* This Method submits the Form Data to the Order-Form Service */   
	postOrderForm(orderForm, activeLang:string)  {
		//Append the active Language to the form variable
		orderForm["lang"] = activeLang;
		return 	this.http.post( (this.submitMessageUrl + "/order-form"), JSON.stringify(orderForm))
	}
}
 