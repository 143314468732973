import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QrcodeDialogComponent } from './_models/qrcode-dialog/qrcode-dialog.component';

@Component({
  selector: 'app-token-card',
  templateUrl: './token-card.component.html',
  styleUrls: ['./token-card.component.scss']
})
export class TokenCardComponent implements OnInit {
  public icons: string[] = ['language', 'lock_open'];
  public borderColor: string = '';
  public content: string;

  constructor(
    public dialogRef: MatDialogRef<TokenCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogSrv: MatDialog,
  ) { }

  public ngOnInit(): void {
    this.content = window.location.href;
  }

  public getQrCode(): void {
    this._dialogSrv.open(QrcodeDialogComponent);
  }
}
