<carousel interval="5000">
  <video *ngIf="slideToggle" style="width: 100%; display: block" #videoPlayer [muted]="isSafari" (ended)="slideToggle = false">
    <source src="assets/media/videos/new-tera-zukuri.mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
    
  <slide *ngIf="!slideToggle" class="animated fadeIn">
    <img src="assets/images/Home_page_pic_1.jpg" alt="first slide" style="display: block; width: 100%;">
  </slide>
  <slide *ngIf="!slideToggle" class="animated fadeIn">
    <img src="assets/images/Home_page_pic_2.jpg" alt="second slide" style="display: block; width: 100%;">
  </slide>
  <slide *ngIf="!slideToggle" class="animated fadeIn">
    <img src="assets/images/Home_page_pic_3.jpg" alt="third slide" style="display: block; width: 100%;">
  </slide>
  <slide *ngIf="!slideToggle" class="animated fadeIn">
    <img src="assets/images/Home_page_pic_4.jpg" alt="third slide" style="display: block; width: 100%;">
  </slide>
</carousel>