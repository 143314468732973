import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from './pipes/pipes.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CommonHtmlModuleModule } from './html/common-html-module.module';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { PsyBreadcrumbsModule } from '@psyora/psy-breadcrumbs';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxQrWithImageModule } from '@psyora/ngx-qr-with-image';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
// import { SlateModule } from 'slate-angular';


const modules = [ 
  CommonHtmlModuleModule,
  PsyBreadcrumbsModule,
  CommonModule,
  BrowserModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  BrowserAnimationsModule,  
  PipesModule,
  UiComponentsModule,
  NgbModule,
  NgxDropzoneModule,
  TabsModule,
  MaterialModule,
  CarouselModule,
  FontAwesomeModule,
  NgCircleProgressModule.forRoot({}), 
  TranslateModule.forChild(),
  PsyBreadcrumbsModule,  
  NgxQrWithImageModule,
  PdfViewerModule,
  NgxExtendedPdfViewerModule
  // SlateModule
]

@NgModule({
  exports: [modules]
})

export class SharedModule { }
