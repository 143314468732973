import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {  faBuilding,faUserCog,faUserCircle,faChevronCircleDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'admin-portal-layout',
  templateUrl: './admin-portal-layout.component.html',
  styleUrls: ['./admin-portal-layout.component.css']
})
export class AdminPortalLayoutComponent {

  public isExpanded: boolean;
	readonly faBuilding: IconDefinition = faBuilding;
	readonly faUserCog: IconDefinition = faUserCog;
	readonly faUserCircle: IconDefinition = faUserCircle;
	readonly faChevronCircleDown: IconDefinition = faChevronCircleDown;

  
  readonly navigationMenu = [
    { 'icon': 'dashboard', 'svgIcon': '', 'routerLink': '/admin/dashboard', 'menu': 'Dashboard' }, 
    { 'icon': '', 'svgIcon': 'jp-craftsman', 'routerLink': '/admin/supplier', 'menu': 'Supplier' },
    { 'icon': '', 'svgIcon': 'jp-woodwork', 'routerLink': '/admin/product', 'menu': 'Product' },
    { 'icon': '', 'svgIcon': 'jp-hanami', 'routerLink': '/admin/lot', 'menu': 'Lot' },
    { 'icon': '', 'svgIcon': 'jp-bag', 'routerLink': '/admin/inventory', 'menu': 'Inventory' },
    { 'icon': '', 'svgIcon': 'jp-stall', 'routerLink': '/admin/distributor', 'menu': 'Distributor' },
    { 'icon': '', 'svgIcon': 'jp-surfing', 'routerLink': '/admin/customer', 'menu': 'customer' },
    { 'icon': '', 'svgIcon': 'jp-music', 'routerLink': '/admin/enquiry', 'menu': 'Enquiry' } ,
    { 'icon': 'apartment', 'svgIcon': '', 'routerLink': '/admin/enquiry', 'menu': 'Apartment' } 

  ]

  constructor(readonly authSrv: AuthService) { }

}