import { Injectable } from '@angular/core';
import { HttpResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpLoaderService } from './http-loader.service';

@Injectable()

export class HttpLoaderInterceptor implements HttpInterceptor {

    private requests: HttpRequest<any>[] = [];

    constructor(private loaderService: HttpLoaderService) { }


    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0)
            this.requests.splice(i, 1);

        this.loaderService.isLoading.next(this.requests.length > 0);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requests.push(req);
        document.getElementsByTagName("BODY")[0].classList.add('http-cursor-load')
        this.loaderService.isLoading.next(true);

        return Observable.create(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => { this.removeRequest(req); observer.error(err); },
                    () => { this.removeRequest(req); observer.complete(); });
            // teardown logic in case of cancelled requests
            return () => {
                document.getElementsByTagName("BODY")[0].classList.remove('http-cursor-load')
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}
