<div class="row">
    <div class="col-md-4">
        <div >
            <pmm-ltcommitment-carousel></pmm-ltcommitment-carousel>
        </div>
    </div>
    <div class="col-md-8">
        <div>
            <blockquote class="content">
                {{ 'projectMM.ltcommitment.content_1' | translate}}
                <br><br>
                {{ 'projectMM.ltcommitment.content_2' | translate}}
                <br><br>
                {{ 'projectMM.ltcommitment.content_3' | translate}}
                <br><br>
            </blockquote>
        </div>
    </div>
</div>