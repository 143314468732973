<div class="row">
    <div class="col-md-4">
        <div >
            <hwmat-finishing-carousel></hwmat-finishing-carousel>
        </div>
    </div>
    <div class="col-md-8">
        <div>
            <blockquote class="content">
                {{ 'aboutPashmina.howWeMake.finishing.content-1' | translate}}
                <br><br>
                {{ 'aboutPashmina.howWeMake.finishing.content-2' | translate}}
            </blockquote>
        </div>
    </div>
</div>