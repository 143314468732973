import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'psyFilterForES',
    pure: false
})

export class PsyFilter implements PipeTransform {
    /* transform(items: any[], filter: Record<string, any>): any {
        if (!items || !filter) {
          return items;
        }
  
        const key = Object.keys(filter)[0];
        const value = filter[key];
  
        return items.filter((e) => e[key].indexOf(value) !== -1);
    };
 */
    transform(items: any[], filterArgs: string, ): any[] {
        if (!items || !filterArgs) return items;

        filterArgs = filterArgs.toLowerCase();
        return items.filter( item => {        
            return  item._source.uniqueId.toLowerCase().includes(filterArgs) || 
                    item._source.product.toLowerCase().includes(filterArgs);
        });
    };
};