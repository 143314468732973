<mat-card class="main-grid" *ngIf="state == eTokenPostInfoState['view']">
  <div class="header-grid">
    <img mat-card-avatar *ngIf="dialogData" [src]="dialogData.preview">
    <div class="titles">
      <strong> {{ dialogData.addedBy.displayName }} </strong> {{dialogData.addedOn | date}}
      <p>
        <mat-icon class="pub-icon">
          {{ dialogData.type == eTokenType.public ? 'public' : 'lock' }}
        </mat-icon>
        <span>{{dialogData.title}}</span>
      </p>
    </div>
  </div>
  <div class="content-grid">
    <div style="width:100%;">
      <router-outlet name="popupContent"></router-outlet>
    </div>
  </div>
  <div class="buttons-grid">
    <div class="tag-attach-icons" *ngIf="tokenPostDetails">
      <button mat-mini-fab *ngIf="tokenPostDetails?.content?.info?.ops?.length > 0" color="primary">
        <mat-icon (click)="getAssociations()">tag</mat-icon>
      </button>
      <button mat-mini-fab *ngIf="tokenPostDetails?.content?.files?.length > 0" color="primary" class="ml-2">
        <mat-icon (click)="getFiles()">attach_file</mat-icon>
      </button>
      <button mat-mini-fab color="primary" *ngIf="tokenPostDetails?.content?.videos?.length > 0" class="ml-2">
        <mat-icon>video_file</mat-icon>
      </button>
      <button mat-mini-fab color="primary" *ngIf="tokenPostDetails?.content?.timeline?.length > 0" class="ml-2">
        <mat-icon>timeline</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>

  <div class="scrollbar" (mouseover)="setArrowStatus()" (scroll)="setArrowStatus()">
    <div class="arrow left-arrow" *ngIf="arrowStatus.left && sbState == 'done'" (click)="scrollBy('-')">
      <fa-icon [icon]="faChevronLeft"></fa-icon>
    </div>
    <mat-chip-list>
      <mat-chip *ngFor="let chip of chipsList"> {{chip}} </mat-chip>
    </mat-chip-list>
    <div class="arrow right-arrow" *ngIf="arrowStatus.right && sbState=='done'" (click)="scrollBy('+')">
      <fa-icon [icon]="faChevronRight"></fa-icon>
    </div>
  </div>
  <mat-icon class="clear" mat-dialog-close>clear</mat-icon>
</mat-card>