<footer id="footer" class="dark">
    <div id="copyrights">
        <div class="container clearfix">
            <div class="row">
                <div class="col-sm-7" id="copyright">
                    <div class="row">
                        <div class="col-sm 8">
                            Copyrights © 2019 All Rights Reserved by tera-zukuri Inc.
                        </div>


                        <!-- Drop Up-->
                        <div class="col-sm-4 btn-group dropup">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Credits
                            </button>
                            <div id="credits" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div *ngFor="let credit of credits;">
                                    <a class="dropdown-item credit-title">{{credit.title}}</a>

                                    <a class="dropdown-item credit-person" *ngFor="let person of credit.values">
                                        {{ person }}</a>
                                    <div class="credit-section-end"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Drop Up -->

                <div class="col-sm-5">
                    <div id="links" class="row">

                        <div class="p-2 bd-highlight">
                            <span class="fa fa-instagram"></span>
                            <a href="https://www.instagram.com/tera_zukuri/?igshid=1nh4v4442tdgs" target="_blank">
                                Instagram</a>
                        </div>
                        <div class="p-2 bd-highlight">
                            <span class="fa fa-envelope"></span> akewises@hotmail.com
                        </div>
                        <div class="p-2 bd-highlight">
                            <span class="fa fa-phone"></span> 070-3960-5704
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>
</footer>