import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomeModule } from './home/home.module';
import { ProjectMMModule } from './project-mm/project-mm.module';
import { AboutPashminaModule } from './about-pashmina/about-pashmina.module';
import { ContactModule } from './contact/contact.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    HomeModule,
    ProjectMMModule,
    AboutPashminaModule,
    ContactModule,
    RouterModule.forChild([]),
    TranslateModule.forChild()
  ]
})

export class PagesModule { }
