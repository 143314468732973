import { Component, OnInit } from '@angular/core';
import { SwalAlertService } from 'src/app/services/swal-alert.service';
import { TokenService } from '../../_services/token.service';
import { eTokenType, ITokenPostPreview } from '../../_types/enum';
import { ITokenPostListItem } from '../../_types/interface';

@Component({
  selector: 'app-token-public',
  templateUrl: './token-public.component.html',
  styleUrls: ['./token-public.component.scss']
})

export class TokenPublicComponent implements OnInit {

  public tokenPublicInfo: ITokenPostListItem[];
  constructor(
    private _dataSrv: TokenService,
    private _aletSrv: SwalAlertService
  ) { }

  public ngOnInit(): void {
    this._dataSrv.getTokenPublicInfoList().subscribe({
      next: data => this.tokenPublicInfo = data,
      error: err => this._aletSrv.swalAlertFailure(err)
    });
  }

  public onWheelPublic(event: WheelEvent): void {
    if (event.deltaY > 0) document.getElementById('public-wheel')!.scrollLeft += 40;
    else document.getElementById('public-wheel')!.scrollLeft -= 40;
  }
}
