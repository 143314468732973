<div [ngSwitch]='state'>
    <div class="image" *ngSwitchCase="eFileState.image">
        <img [src]="file.url" alt="file.name">
    </div>
    <div *ngSwitchCase="eFileState.pdf">
        <ngx-extended-pdf-viewer [src]="file.url" useBrowserLocale="true"  
            [zoom]="'page-width'" [mobileFriendlyZoom]="true"
            [showPresentationModeButton]="true"
            [showSecondaryToolbarButton]="false"
            [showSpreadButton]="false"
            [showPrintButton]="false"
            [showOpenFileButton]="false"
            [showRotateButton]="false"
            [showDownloadButton]="false" 
            [showZoomButtons]="true">
        </ngx-extended-pdf-viewer>
    </div>
</div>

<!-- <pdf-viewer [src]="pdfSrc" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="false" [zoom]="1"
    [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="false" [external-link-target]="'blank'"
    style="height: 500px; width: 400px;"
    [autoresize]="true" [show-borders]="false" (error)="pdfViewerError($event)" 
    (text-layer-rendered)="textLayerRendered($event)"
    (after-load-complete)="callBackFn($event)">
</pdf-viewer>
 -->
