import { Component, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBuilding, faUserCog, faUserCircle, faChevronCircleDown, faBackward } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-settings-layout',
  templateUrl: './user-settings-layout.component.html',
  styleUrls: ['./user-settings-layout.component.css']
})
export class UserSettingsLayoutComponent {

  public isExpanded: boolean;
  readonly faBuilding: IconDefinition = faBuilding;
	readonly faUserCog: IconDefinition = faUserCog;
	readonly faUserCircle: IconDefinition = faUserCircle;
	readonly faChevronCircleDown: IconDefinition = faChevronCircleDown;
	readonly faBackward: IconDefinition = faBackward;
  
  readonly navigationMenu = [
    { 'icon': 'dashboard', 'svgIcon': '', 'routerLink': '/admin/dashboard', 'menu': 'Dashboard' }, 
    { 'icon': 'account_circle', 'svgIcon': '', 'routerLink': '/me/info', 'menu': 'My Info' }, 
    { 'icon': 'security', 'svgIcon': '', 'routerLink': '/me/security', 'menu': 'Security' },
  ]

  constructor(readonly authSrv: AuthService) { }

}
