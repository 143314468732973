<mat-drawer-container class="admin-container mat-typography" style="height: 100%;" autosize>
    <mat-drawer #drawer mode="side" disableClose="true" opened="true">
        <div class="d-flex align-items-end">
            <button mat-mini-fab (click)="isExpanded = !isExpanded" color="accent" style="margin: 10px;">
                <mat-icon aria-label="Menu">menu</mat-icon>
            </button>

            <div>
                <h2 *ngIf="isExpanded" class="sidenav-title">
                    Admin Portal
                </h2>
            </div>
        </div>

        <mat-nav-list>
            <mat-list-item *ngFor="let nav of navigationMenu" [routerLink]="nav.routerLink">
                <mat-icon [svgIcon]="nav.svgIcon" mat-list-icon>{{ nav.icon }}</mat-icon>
                <h4 mat-line *ngIf="isExpanded">{{ nav.menu }}</h4>
            </mat-list-item>
        </mat-nav-list>
    </mat-drawer>

    <div class="admin-sidenav-content">

        <mat-toolbar color="primary" class="d-flex justify-content-between">
            <a class="navbar-brand" routerLink="/admin"> <img src="assets/images/logo-image.png"></a>

            <h2> <fa-icon [icon]="faBuilding"></fa-icon><b> Company Settings</b> </h2>

            <div ngbDropdown *ngIf="authSrv.isLoggedIn && authSrv.currentUser">

                <button mat-button [routerLink]="['/admin']">
                    <fa-icon [icon]="faBackward"></fa-icon> Back to Main Panel
                </button>

                <button mat-button [routerLink]="['/me/info']">
                    <fa-icon [icon]="faUserCog"></fa-icon> User Settings
                </button>

                <button mat-button id="userSettings" ngbDropdownToggle style="padding: 1em;">
                    <mat-icon color="primary" class="user-icon mr-2">account_circle</mat-icon>
                    <span class="mr-2">{{ authSrv.currentUser }}</span>
                    <fa-icon [icon]="faChevronCircleDown"></fa-icon>
                </button>

                <div ngbDropdownMenu aria-labelledby="userSettings" class="mr-0">
                    <button [routerLink]="['/me/security']" ngbDropdownItem>Reset Password</button>
                    <button (click)="authSrv.signOutOfCognito()" ngbDropdownItem>Logout</button>
                </div>
            </div>
        </mat-toolbar>

        <div class="ml-3 mt-1 mr-2">
            <router-outlet></router-outlet>
        </div>
    </div>
</mat-drawer-container>