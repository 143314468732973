import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCross } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-clarity-token-post-mobile',
  templateUrl: './clarity-token-post-mobile.component.html',
  styleUrls: ['./clarity-token-post-mobile.component.scss']
})
export class ClarityTokenPostMobileComponent implements OnInit {

  readonly faCross: IconDefinition = faCross;

  constructor(
    public dialogRef: MatDialogRef<ClarityTokenPostMobileComponent>
  ) { }

  public ngOnInit(): void {
    let elem = document.documentElement;
    let methodToBeInvoked = elem.requestFullscreen || elem['mozRequestFullscreen'] || elem['webkitRequestFullscreen'] || elem['msRequestFullscreen'];

    if (methodToBeInvoked) methodToBeInvoked.call(elem);
  }

  public ngOnDestroy(): void {

    const cancellFullScreen = document.exitFullscreen || document['mozCancelFullScreen'] || document['webkitExitFullscreen'] || document['msExitFullscreen'];
    if (cancellFullScreen) cancellFullScreen.call(document);
    
    this.dialogRef.close();
  }

}
