import { Inject, Injectable } from "@angular/core";
import swal from 'sweetalert2';

export interface ICognitoAuthError {
    "code": string,
    "message": string,
    "name": string
}

@Injectable({
    providedIn: 'root'
})

export class CognitoAuthError {
    
    private _error: ICognitoAuthError 

    constructor(@Inject(Object) authError: ICognitoAuthError ) {
        this._error = authError;
        this._handleCognitoError();
    };

    private _handleCognitoError(): void {
        console.log(this._error)
        switch (this._error.code) {
            case ("PasswordResetRequiredException"): {
                const errMsg = "Sign-in is successful, however you are required to reset your password to continue.";
                return this._displaySignInError("PasswordResetRequied", errMsg, "Reset My Password");
            };
            case ("NotAuthorizedException"): {
                return this._displaySignInError("Action isn't allowed!");
            };
            default: {
                return this._displaySignInError("We couldn't sign you in!");
            };
        };
    };

    //Error Handlers
    private _displaySignInError(errTitle?: string, errMessage?: string, confirmBtnTxt?: string): void {
        swal.fire({
			title: errTitle || "We couldn't sign you in",
			text: errMessage || this._error.message,
			icon: "error",
  			confirmButtonText: confirmBtnTxt || "OK",
		});
    };
};
