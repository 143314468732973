<div class="container">
    <section id="product-1">
        <h1 class="page-heading">{{ 'projectMM.product.prod1.title' | translate }}</h1>
        <prj-product-dbbase></prj-product-dbbase>
    </section>
    <section id="product-2">
        <h1 class="page-heading">{{ 'projectMM.product.prod2.title' | translate}}</h1>
        <prj-product-whbase></prj-product-whbase>
    </section>
    <br>
</div>
