<div class="container">
    <div class="row">
        <div id="carousel">
            <home-carousel></home-carousel>
        </div>
    </div>
    <br> 
    <div class="row">
        <div>
            <home-content></home-content>
        </div>  
    </div>
</div>
