import { Component} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-order-form-modal',
  templateUrl: './order-form-modal.component.html',
  styleUrls: ['./order-form-modal.component.css']
})
export class OrderFormModalComponent {
 
  constructor(public dialogRef: MatDialogRef<OrderFormModalComponent>) { }
  
}
