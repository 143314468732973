import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Route } from '@angular/compiler/src/core';

@Component({
  selector: 'home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrls: ['./home-carousel.component.css']
})
export class HomeCarouselComponent {
 	//Action 1 - Toggle Video and Images -- Adi
	//Action 2 - Control Video Play -- Adi

	@ViewChild('videoPlayer') public videoPlayer: ElementRef;

	public slideToggle: boolean;
	public isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	constructor(private dialog: MatDialog, private _router: Router) {

		this.slideToggle = true

		//Below will ensure that when you navigate out of home and back to home, images are rendered
		if (this.dialog.openDialogs.length == 0)	this.slideToggle = false

		this.dialog.afterAllClosed.subscribe( (event) => {
			//Below will ensure that video is placed only after modal is closed and inside the home page
			if (this.dialog.openDialogs.length == 0 && this._router.url === '/home' && this.videoPlayer) 
				this.videoPlayer.nativeElement.play()
		}) 
	}
}
