import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ITokenInfo, ITokenPost, ITokenPostListItem } from "../_types/interface";

@Injectable({
    providedIn: 'root'
})

export class TokenService {
    private _mockApi: string = "assets/mock-api/token"
    constructor(private _http: HttpClient) { }

    getTokenInfo(id?: string): Observable<ITokenInfo> {
        return this._http.get<ITokenInfo>(`${this._mockApi}/token.json`)
    }

    getTokenPublicInfoList(id?: string): Observable<ITokenPostListItem[]> {
        return this._http.get<ITokenPostListItem[]>(`${this._mockApi}/public-info-list.json`)
    }

    getTokenPrivateInfoList(id?: string): Observable<ITokenPostListItem[]> {
        return this._http.get<ITokenPostListItem[]>(`${this._mockApi}/private-info-list.json`)
    }

    getTokenPostInfo(id: string, postId: number): Observable<ITokenPost> {
        return this._http.get<ITokenPost>(`${this._mockApi}/post.${postId}.json`)
    }
}