import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserLayout1Module } from './user-layout-1/user-layout-1.module';
import { AdminPortalLayoutModule } from './admin-portal-layout/admin-portal-layout.module';
import { UserSettingsLayoutModule } from './user-settings-layout/user-settings-layout.module';
import { CompanySettingsLayoutComponent } from './company-settings-layout/company-settings-layout.component';
import { ProjectsComponent } from './projects/projects.component';
import { SlateAngularDemoComponent } from './slate-angular-demo/slate-angular-demo.component';


@NgModule({
  declarations: [CompanySettingsLayoutComponent, ProjectsComponent, SlateAngularDemoComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
 
    UserLayout1Module,
    AdminPortalLayoutModule,
    UserSettingsLayoutModule
  ],
  exports: []
})
export class LayoutsModule { }
