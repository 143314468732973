import { NgModule } from '@angular/core';
import { OrderFormComponent } from './order-form.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material.module';
import { OrderFormModalComponent } from './order-form-modal/order-form-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    OrderFormComponent,
    OrderFormModalComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    RouterModule.forChild([]),
    MaterialModule
  ]
})
export class OrderFormModule { }
