<div class="container">
    <div class="row">
        <!-- Navigation Tabs -->
        <div id="list-example" class="nav nav-pills">
            <a class="nav-link" [routerLink]="['/HowWeMakeatTera']" fragment="preSpinning">{{ 'aboutPashmina.howWeMake.preSpinning.nav' | translate}}</a>
            <a class="nav-link" [routerLink]="['/HowWeMakeatTera']" fragment="spinning">{{ 'aboutPashmina.howWeMake.spinning.nav' | translate}}</a>
            <a class="nav-link" [routerLink]="['/HowWeMakeatTera']" fragment="weaving">{{ 'aboutPashmina.howWeMake.weaving.nav' | translate}}</a>
            <a class="nav-link" [routerLink]="['/HowWeMakeatTera']" fragment="finishing">{{ 'aboutPashmina.howWeMake.finishing.nav' | translate}}</a>
        </div>
    </div>
        <!-- Navigation Tabs Content-->
    <div class="content">
        <div class="sub-head">
            {{ 'aboutPashmina.howWeMake.preSpinning.nav' | translate}}
        </div>
        <section id="preSpinning" class="home-section">
            <hwmat-pre-spinning></hwmat-pre-spinning>
        </section>
        <div class="sub-head">
            {{ 'aboutPashmina.howWeMake.spinning.nav' | translate}}
        </div>
        <section id="spinning" class="about-section">
            <hwmat-spinning></hwmat-spinning>
        </section>
        <div class="sub-head">
            {{ 'aboutPashmina.howWeMake.weaving.nav' | translate}}
        </div>
        <section id="weaving" class="contact-section">
            <hwmat-weaving></hwmat-weaving>
        </section>
        <div class="sub-head">
            {{ 'aboutPashmina.howWeMake.finishing.nav' | translate}}
        </div>
        <section id="finishing" class="contact-section">
           <hwmat-finishing></hwmat-finishing>
        </section>
    </div>
</div>