import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderFormSubmitService } from './order-form-submit.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { OrderFormModalComponent } from './order-form-modal/order-form-modal.component';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.css']
})
export class OrderFormComponent {
  public optionsHearabtTera: Array<String> = [];

  constructor(private translate: TranslateService,
              private orderForm: OrderFormSubmitService,
              private matDialog: MatDialog) {

    this.optionsHearabtTera.push(
      this.translate.instant('order.contact.hearTera.option1'),
      this.translate.instant('order.contact.hearTera.option2'),
      this.translate.instant('order.contact.hearTera.option3')
    );
  };
  
  public submit(order): void {
    console.log(order);
    /* let activeLang: string = this.translate.currentLang;
    this.orderForm.postOrderForm(order.value, activeLang).subscribe( (res) => {
      alert(res);
      //this.openOrderFormModal();
    }); */
  }

  public openOrderFormModal(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "order-form-modal";
    dialogConfig.height = "17rem";
    dialogConfig.width = "35rem";
    // https://material.angular.io/components/dialog/overview
    const modalDialog = this.matDialog.open(OrderFormModalComponent, dialogConfig);
  }
}
