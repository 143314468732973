import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class BrowserStateService {
    
    public browserState: string

    constructor() { }

}