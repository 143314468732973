<div class="row">
    <div class="col-md-4">
        <div >
            <pmm-concept-carousel></pmm-concept-carousel>
        </div>
    </div>
    <div class="col-md-8">
        <div>
            <blockquote class="content">
                {{ 'projectMM.concept.content_1' | translate}}
                <br><br>
                {{ 'projectMM.concept.content_2' | translate}}
                <br><br>
                {{ 'projectMM.concept.content_3' | translate}}
                <br><br>
                {{ 'projectMM.concept.content_4' | translate}}
            </blockquote>
        </div>
    </div>
</div>